<template>
  <ion-page>
    <page-header title="Kontakte" />
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-lg="6" offset="0" offset-lg="3">

            <h2>Freunde <ion-badge color="primary">5</ion-badge></h2>
            <ion-item v-for="i in 5" :key="i" lines="none">
              <ion-avatar slot="start">
                <img src="https://via.placeholder.com/100">
              </ion-avatar>
              <ion-label>
                <h3>Jane Doe</h3>
                <p>Wallstreet {{ i }}, 12345 Berlin</p>
              </ion-label>
            </ion-item>

            <h2>Freunde von Freunden <ion-badge color="primary">15</ion-badge></h2>
            <ion-item v-for="i in 15" :key="i" lines="none">
              <ion-avatar slot="start">
                <img src="https://via.placeholder.com/100">
              </ion-avatar>
              <ion-label class="align-row">
                <h3>John Doe</h3>
                <p>Wallstreet {{ i }}, 12345 Berlin</p>
                <ion-fab vertical="center" horizontal="end" slot="fixed">
                  <ion-fab-button size="small">
                    <ion-icon :icon="personAddOutline" title="Freundesanfrage"></ion-icon>
                  </ion-fab-button>
                </ion-fab>
              </ion-label>
            </ion-item>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonItem, IonAvatar, IonLabel, IonBadge, IonFab, IonFabButton, IonIcon, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { personAddOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

export default  {
  name: 'TabContacts',
  components: { IonContent, IonPage, IonItem, IonAvatar, IonLabel, IonBadge, IonFab, IonFabButton, IonIcon, IonGrid, IonCol, IonRow, PageHeader },
  setup() {
    return {
      personAddOutline, 
    }
  }
}
</script>
