
import { IonPage, IonContent, IonItem, IonAvatar, IonLabel, IonBadge, IonFab, IonFabButton, IonIcon, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { personAddOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

export default  {
  name: 'TabContacts',
  components: { IonContent, IonPage, IonItem, IonAvatar, IonLabel, IonBadge, IonFab, IonFabButton, IonIcon, IonGrid, IonCol, IonRow, PageHeader },
  setup() {
    return {
      personAddOutline, 
    }
  }
}
